import Log from "@common/Log";
import { AdminTabs, AdminView } from "@frontend/views/AdminView";

/**
 * CS 310 Admin view does not really differ at all from the stock Classy view
 * except for the addition of the Viz button.
 */
export class ClassyAdminView extends AdminView {
	constructor(remoteUrl: string, tabs: AdminTabs) {
		Log.info("CustomAdminView::<init>(..)");
		super(remoteUrl, tabs);
	}

	public renderPage(name: string, opts: any) {
		Log.info("CustomAdminView::renderPage( " + name + ", ... ) - start; options: " + JSON.stringify(opts));
		super.renderPage(name, opts);

		if (this.isAdmin === false) {
			Log.info("CustomAdminView::renderPage( " + name + ", ... ) - !isAdmin");
			this.setTabVisibility("AdminGradeTab", false);
		} else {
			Log.info("CustomAdminView::renderPage( " + name + ", ... ) - isAdmin");
		}
	}

	protected async handleAdminDashboard(opts: any): Promise<void> {
		Log.trace("CustomAdminView::handleDashboard(..) - start");
		return this.dashTab.init(opts).then((res) => {
			// if (!document.querySelector("#vizButton")) {
			//     const UIRow = document.querySelector("#AdminDashboardList > div");
			//     const button = document.createElement("button");
			//     button.id = "vizButton";
			//     button.style.marginLeft = "100px";
			//     button.innerHTML = "Viz (beta)";
			//     const path = Factory.getInstance().getHTMLPrefix() + "/Viz/public/index.html";
			//     button.addEventListener("click", () => window.open(path));
			//     UIRow.append(button);
			//     return res;
			// }
		});
	}
}
